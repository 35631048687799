/** @format */
import {
  FLEET_COMPANIES,
  LANDING_PAGE_AI_POWERED_FUEL_RISK_MANAGEMENT_PLATFORM,
  LANDING_PAGE_ROADFLEX_GEOTAB_ADVANTAGES,
  TESTIMONIALS,
} from "@roadflex/constants";
import { HeadSeo } from "@roadflex/react-components-www";
import { useRouter } from "next/router";
import { useEffect } from "react";
import AlternateComponent from "../components/common-components/alternate";
import CallToAction from "../components/common-components/call-to-action/call-to-action";
import RoadFlexCustomers from "../components/common-components/customer-success/customer-success";
import Testimonials from "../components/common-components/customer-success/testimonials";
import Panel1 from "../components/landing-page/panel1";
import Panel10 from "../components/landing-page/panel10";
import Panel11 from "../components/landing-page/panel11";
import Panel3 from "../components/landing-page/panel3";
import Panel4 from "../components/landing-page/panel4";
import Panel5 from "../components/landing-page/panel5";
import Panel6 from "../components/landing-page/panel6";

export function Index() {
  const router = useRouter();
  useEffect(() => {
    const { query } = router;
    const { scrollTo, testimonials, whyRoadflex } = query;
    let element = null;

    if (scrollTo) {
      element = document.querySelector(`#${scrollTo}`);
    } else if (testimonials !== undefined) {
      element = document.querySelector(`#testimonials`);
    } else if (whyRoadflex !== undefined) {
      element = document.querySelector(`#comparison-panel`);
    }
    const header = document.querySelector("#header");
    if (element) {
      window.scrollTo({
        top:
          element.getBoundingClientRect().top +
          window.scrollY -
          header.getBoundingClientRect().height,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    <>
      <HeadSeo />
      <Panel1 />
      <RoadFlexCustomers fleetCompanies={FLEET_COMPANIES} />
      <Panel3 />
      <Panel6 />
      <AlternateComponent
        title1="AI-Powered Fuel Risk Management Platform&#8482;"
        subTitle1="Learn why top fleets ditched their legacy fueling solutions for our AI-Powered Fuel Risk Management Platform&#8482;"
        features1={LANDING_PAGE_AI_POWERED_FUEL_RISK_MANAGEMENT_PLATFORM}
        icon="CheckCircleIcon"
        backgroundColor="darkblue"
      ></AlternateComponent>
      <Panel4 />
      <AlternateComponent
        title1="Level-Up The Way You Manage Your Fleet"
        subTitle1="Modernize your fleet operations with more accurate fleet data, AI-powered reporting and powerful integrations."
        features1={LANDING_PAGE_ROADFLEX_GEOTAB_ADVANTAGES}
        icon="CheckCircleIcon"
      ></AlternateComponent>
      <Panel5 />
      <Testimonials
        testimonials={TESTIMONIALS.filter(
          (testimonial) =>
            testimonial.company !== "Central Valley Services" &&
            testimonial.company !== "Route 6 Logistics Inc.",
        )}
        uiType="vertical"
        header="Modern Fleets Trust RoadFlex As Their Partner"
      />
      <Panel10 />
      <Panel11 />
      <CallToAction></CallToAction>
    </>
  );
}

export default Index;
